import { useState } from "react";
import FsLightbox from "fslightbox-react";
import { Link } from "react-router-dom";
import { FaLinkedinIn, FaPinterestP, FaXTwitter } from "react-icons/fa6";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay"; // Import Swiper Autoplay CSS
import { Pagination, Autoplay } from "swiper/modules"; // Import Autoplay module
import buttonShape from "/images/button-shape-1.png";
import { LiaPlayCircleSolid } from "react-icons/lia";
import "./banner.css";
import { UseHeaderSlider } from "../../Components/useHeaderSlider";
import { uploadUrl } from "../../Components/urls";
const Banner = () => {
  const HeaderSlider = UseHeaderSlider();
  // console.log(HeaderSlider.length)
  const [toggler, setToggler] = useState(false);

  const settings = {
    loop: HeaderSlider.length > 1,  
    speed: 2000,
    autoplay: {
      delay: 25000,
      disableOnInteraction: false,
    },
    duration: 13000,
    slidesPerView: 1, 
    slidesPerGroup: 1, 
  };
  
  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + ' pagination-bullet"></span>';
    },
  };
  return (
    <div className="relative">
      {/* <Swiper {...settings} pagination={pagination} modules={[Pagination]}> */}
      <Swiper {...settings} pagination={pagination} modules={[Pagination, Autoplay]}>

        {HeaderSlider &&
          HeaderSlider.map((head, i) => (
            <SwiperSlide key={i}>
              <section
              key={i}
                style={{
                  backgroundImage: `url(${uploadUrl}${head.HeadsliderImage} )`,
                }}
                className="bg-cover bg-left lg:bg-center bg-no-repeat h-[750px] sm:h-[700px] md:h-[750px] lg:h-[760px] xl:h-[960px] flex items-center"
              >
                <div className="Container">
                  <div className="pt-36">
                    <div className="relative banner-content">
                      <h5 className="font-AlbertSans text-PrimaryColor-0 font-medium">
                        {head.HeadSliderTXT1}
                      </h5>
                      <h1 className="font-AlbertSans font-extrabold text-white text-[30px] sm:text-[56px] md:text-[70px] lg:text-[50px] xl:text-[60px] 2xl:text-[68px]">
                        {head.HeadSliderTXT2A}
                      </h1>
                      <h1 className="font-AlbertSans font-extrabold text-white text-[30px] sm:text-[56px] md:text-[70px] lg:text-[50px] xl:text-[60px] 2xl:text-[68px] -mt-3 sm:-mt-5 md:-mt-7 lg:-mt-5">
                        {head.HeadSliderTXT1B}
                      </h1>
                      <p className="font-AlbertSans text-lg text-white mb-10">
                      {head.HeadSliderTXT3A}
                     <br className="hidden md:block" /> 
                    {head.HeadSliderTXT3B} 
                  </p> 
                      <div className="flex flex-col sm:flex-row gap-5">
                        <Link to={"/about"}>
                          <button className="primary-btn">
                          {head.HeadSliderButton}
                            <img src={buttonShape} draggable="false" />
                          </button>
                        </Link>
                        <div className="text-lg font-AlbertSans font-medium text-white flex items-center gap-4">
                          <button className="h-[58px] w-[58px] rounded-full bg-[#355250] flex justify-center items-center relative z-10 before:absolute before:top-0 before:left-0 before:w-full before:h-full before:bg-[#355250] before:opacity-50 before:-z-10 before:rounded-full before:animate-ping">
                            <LiaPlayCircleSolid
                              size={"34"}
                              className="text-PrimaryColor-0"
                              onClick={() => setToggler(!toggler)}
                            />
                          </button>
                          Watch Video
                        </div>
                        <FsLightbox
                          toggler={toggler}
                          sources={[ head.HeadSliderVideo 
                          ]}  
                        />
                      </div>
                      {/* <div className="banner-share hidden xl:flex gap-[100px] items-center -rotate-90 absolute top-full -translate-y-1/2 xl:-left-[16%] 2xl:-left-[23%]">
                        <h5 className="font-AlbertSans text-white relative before:absolute before:top-1/2 before:-right-[82px] before:w-16 before:h-[1px] before:bg-[#657977]">
                          FOLLOW US
                        </h5>
                        <ul className="flex gap-7 items-center">
                          <li>
                            <Link
                              to={"/"}
                              className="rotate-45 transition-all duration-500 text-white hover:text-PrimaryColor-0"
                            >
                              <FaLinkedinIn />
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={"/"}
                              className="rotate-45 transition-all duration-500 text-white hover:text-PrimaryColor-0"
                            >
                              <FaXTwitter />
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={"/"}
                              className="rotate-45 transition-all duration-500 text-white hover:text-PrimaryColor-0"
                            >
                              <FaPinterestP />
                            </Link>
                          </li>
                        </ul>
                      </div> */}
                    </div>
                    <div></div>
                  </div>
                </div>
              </section>
            </SwiperSlide>
          ))} 
      </Swiper>
    </div>
  );
};

export default Banner;
