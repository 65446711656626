/* eslint-disable no-unused-vars */
import serviceShape from "/images/service-shape.png";
import subTitleShape from "/images/sub-title-shape.png";
import { Swiper, SwiperSlide } from "swiper/react";
import ServiceNavigation from "./ServiceNavigation";
import ServiceCard from "./ServiceCard";
import { GoArrowUpRight } from "react-icons/go";
import { UseServicesCards, UseServicesInfo } from "../../Components/useServices";
import { uploadUrl } from "../../Components/urls"

const Service = () => {
  const ServicesInfo = UseServicesInfo()
  const ServiceData = UseServicesCards()
  
  const settings = {
    loop: true,
    spaceBetween: 30,
    speed: 1000,
    autoplay: true,
    breakpoints: {
      320: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 3,
      },
      1200: {
        slidesPerView: 3,
      },
    },
  };
  return (
    <section className="relative pt-28 pb-[120px] bg-[url('/images/service-bg.jpg')] bg-cover bg-no-repeat bg-center">
      <div className="Container">
        <div className="md:-mb-[11.2rem]">
          <h5 className="font-AlbertSans font-medium text-PrimaryColor-0 flex items-center gap-2">
            <img src={subTitleShape} draggable="false" />
            OUR SERVICES
          </h5>
          <h1 className="font-AlbertSans font-bold text-xl leading-6 sm:text-[38px] sm:leading-[48px] md:text-[40px] md:leading-[54px] lg:text-[32px] lg:leading-[42px] xl:text-[40px] xl:leading-[50px] 2xl:text-[46px] 2xl:leading-[56px] text-HeadingColor-0 mt-5 mb-3 border-b border-BorderColor2-0 pb-9">
            {ServicesInfo.ServicesTitle}
          </h1>
        </div>
        <div className="mt-[40px]">
          <Swiper {...settings}>
            {ServiceData.map(
              ({
                ServicesID,
                ServiceCardPhoto,
                ServiceCardIcon, 
                ServiceCardTitle,
                ServiceCardText, 
              }) => {
                return (
                  <>
                    <SwiperSlide key={ServicesID} >
                      <div className="pt-[120px] sm:pt-[180px]">
                        <ServiceCard
                          serviceImg={uploadUrl + ServiceCardPhoto}
                          serviceIcon={uploadUrl+ServiceCardIcon}
                          serviceUrl={"/service_details"}
                          serviceButton=<GoArrowUpRight />
                          serviceTitle={ServiceCardTitle}
                          serviceDesc={ServiceCardText}
                          serviceShape={serviceShape}
                        />
                      </div>
                    </SwiperSlide>
                  </>
                );
              }
            )}
            <ServiceNavigation />
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default Service;
